.main {
    position: relative;
    background-color: #F3F3F3;
    width: 100%;
    height: 100%;
    flex-basis: calc( 100vw - 200px );
    padding: 24px;
    &.fullscreen {
        flex-basis: 100%;
        padding: 0; } }

.button, .input {
    border-radius: 15px; }

.title {
    font-size: 18px;
    color: #0C8298; }

.card {
    background: #FFFFFF;
    box-shadow: 0px 1px 10px #0000001A;
    border-radius: 4px;
    padding: 18px 24px;
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px; }
    .title {
        font-size: 16px;
        color: #0C8298; } }

.ifttt-main {
    position: relative;
    background-color: #0F346E;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; }

.ifttt-content {
    width: 1250px;
    text-align: center; }

.ifttt-title {
    font-size: 64px;
    color: #00D0B9; }

.ifttt-block {
    background: rgba(255,255,255, 0.16);
    border-radius: 35px;
    width: 100%;
    padding: 25px; }

.store-main {
    box-shadow: rgba(0, 0, 0, 0.4) 3px 3px 15px 0px inset;
    background-color: #F5F5F7;
    width: 100%;
    min-height: 100%;
    border-top-left-radius: 20px; }

.common-multi-select {
    border: 1px solid #d9d9d9;
    padding: 0 0 0 0;
    background: #fff;
    color: #535353; }
